import { useEffect, useState } from "react";
import { AdminProjectContext } from "../../contexts/AdminProjectContext";
import { AdminUserContext } from "../../contexts/AdminUserContext";
import { CommonContext } from "../../contexts/CommonContext";
import useAxios from "../../hooks/useAxios";
import { Project } from "../../Models/Project";
import { User } from "../../Models/User";
import {
    ADMIN_VIEWS,
    defaultUser,
    PROJECT_LIST_URL, SAVED_SELECTED_MENU_STORAGE_KEY, SELECTED_PROJECT_STORAGE_KEY,
    USER_LIST_URL,
} from "../../utils/constant";
import { ContainerFluid } from "../ContainerFluid";
import { AdminDashboardView } from "./AdminDashboardView";
import AdminGlobalMenu from "./AdminGlobalMenu";
import NewCashInForm from "./NewCashInForm";
import NewCashOutForm from "./NewCashOutForm";
import AdminProjectsTab from "./projects/AdminProjectsTab";
import NewProjectForm from "./projects/NewProjectForm";
import GlobalUserListTable from "./users/GlobalUserListTable";
import NewUserForm from "./users/NewUserForm";


export const AdminHome = () => {

    const { getWithAxios } = useAxios()

    const [pageIsLoading, setPageIsLoading] = useState(true)

    const [needToRefreshData, setNeedToRefreshData] = useState(true)

    const [selectedMenu, setSelectedMenu] = useState(parseInt(localStorage.getItem(SAVED_SELECTED_MENU_STORAGE_KEY) || ADMIN_VIEWS.DASHBOARD_VIEW.toString()))

    const [globalProjectList, setGlobalProjectList] = useState<Project[]>([])

    const [globalUserList, setGlobalUserList] = useState<User[]>([
        new User(defaultUser)
    ])


    const updateSelectedProject = (p: Project) => {
        setSelectedProject(p)
        localStorage.setItem(SELECTED_PROJECT_STORAGE_KEY, p.uuid!!)
    }


    const [selectedProject, setSelectedProject] = useState<Project>()
    const [selectedProjectParticipant, setSelectedProjectParticipant] = useState<User>()

    const projectContext = {
        projects: globalProjectList,
        updateProjects: setGlobalProjectList,
        selectedProject: selectedProject,
        updateSelectedProject

    }

    const userContext = {
        users: globalUserList,
        updateUsers: setGlobalUserList,
        selectedProjectParticipant,
        updateSelectedProjectParticipant: (u: User) => {
            setSelectedProjectParticipant(u)
        }
    }

    const commonContext = {
        needToRefreshData, setNeedToRefreshData, selectedMenu, updateSelectedMenu: (v: number) => {
            localStorage.setItem(SAVED_SELECTED_MENU_STORAGE_KEY,v.toString())
            setSelectedMenu(v)
        }
    }


    useEffect(() => {
        if (needToRefreshData) {
            setPageIsLoading(true)
            getWithAxios(PROJECT_LIST_URL)
                .then((res) => {
                    // console.log("****** in fetch project list *********")
                    // console.log(res)
                    return setGlobalProjectList(res.map((it: any) => new Project(it)).reverse())
                })
                .catch((err) => {
                    // console.log("*** error lv: 2 ***")
                    console.log(err)
                    if (err.response) console.log(err.response.data)
                })

            getWithAxios(USER_LIST_URL)
                .then((res) => {
                    // console.log("****** in fetch user list *********")
                    // console.log(res)
                    setGlobalUserList(res.map((it: any) => new User(it)))
                    // setPageIsLoading(false)

                })
                .catch((err) => {
                    console.log("*** error lv: 2 ***")
                    console.log(err)
                    if (err.response) console.log(err.response.data)
                })

            setNeedToRefreshData(false)
        }
    }, [selectedMenu, needToRefreshData]);

    useEffect(() => {
        const savedSelectedProject = localStorage.getItem(SELECTED_PROJECT_STORAGE_KEY)
        setSelectedProject(savedSelectedProject ? globalProjectList.filter(it => it.uuid == savedSelectedProject)[0] : globalProjectList[0])
        setPageIsLoading(false)
    }, [globalProjectList]);


    // ------


    return (

        <ContainerFluid>

            <CommonContext.Provider value={{
                needToRefreshData: commonContext.needToRefreshData,
                setNeedToRefreshData: (v: boolean) => commonContext.setNeedToRefreshData(v),
                selectedMenu: commonContext.selectedMenu,
                updateSelectedMenu: commonContext.updateSelectedMenu,
            }}>
                <AdminProjectContext.Provider value={{
                    selectedProject: projectContext.selectedProject,
                    updateSelectedProject: projectContext.updateSelectedProject,
                    projects: projectContext.projects,
                    updateProjects: projectContext.updateProjects
                }}>
                    <AdminUserContext.Provider value={{
                        users: userContext.users,
                        updateUsers: userContext.updateUsers,
                        selectedProjectParticipant: userContext.selectedProjectParticipant,
                        updateSelectedProjectParticipant: userContext.updateSelectedProjectParticipant,
                    }}>
                        {!pageIsLoading &&
                            <div id={"AdminHome"}
                                className={"row m-2 flex-sm-column flex-md-column flex-lg-row justify-content-between align-items-start bg-white min-vh-100"}>
                                {/*col 1*/}
                                <AdminGlobalMenu selectedMenu={setSelectedMenu}></AdminGlobalMenu>

                                {/*col 2*/}

                                {/*current project view*/}
                                {(selectedMenu === ADMIN_VIEWS.DASHBOARD_VIEW) && selectedProject &&
                                    <AdminDashboardView selectedMenu={setSelectedMenu} />}

                                {/*user list view*/}
                                {selectedMenu === ADMIN_VIEWS.USER_LIST_VIEW &&
                                    <GlobalUserListTable selectedMenu={setSelectedMenu}
                                        globalUserList={globalUserList}></GlobalUserListTable>}

                                {/*project list view*/}
                                {selectedMenu === ADMIN_VIEWS.PROJECT_LIST_VIEW &&
                                    <AdminProjectsTab selectedMenu={setSelectedMenu}></AdminProjectsTab>}

                                {/*project creation from*/}
                                {selectedMenu === ADMIN_VIEWS.NEW_PROJECT_VIEW &&
                                    <NewProjectForm selectedMenu={setSelectedMenu}></NewProjectForm>}

                                {/*user creation form*/}
                                {selectedMenu === ADMIN_VIEWS.NEW_USER_VIEW &&
                                    <NewUserForm selectedMenu={setSelectedMenu}></NewUserForm>}

                                {/*new user adhesion to project form*/}
                                {/*{selectedMenu === ADMIN_VIEWS.NEW_PARTICIPANT_VIEW && selectedProject &&*/}
                                {/*  <NewParticipantForm globalUserList={globalUserList}*/}
                                {/*                      selectedMenu={setSelectedMenu}></NewParticipantForm>}*/}


                                {/*add user's cash in to project form*/}
                                {selectedMenu === ADMIN_VIEWS.NEW_CASH_IN_VIEW && selectedProject &&
                                    <NewCashInForm selectedMenu={setSelectedMenu}></NewCashInForm>}
                                    
                                {/* catch out */}
                                {selectedMenu === ADMIN_VIEWS.NEW_CASH_OUT_VIEW && selectedProject &&
                                    <NewCashOutForm selectedMenu={setSelectedMenu}></NewCashOutForm>}

                            </div>}

                    </AdminUserContext.Provider>
                </AdminProjectContext.Provider>
            </CommonContext.Provider>
        </ContainerFluid>
    )
}

